.titlePage {
  font-size: 22px !important;
  line-height: 30px !important;
  font-weight: bold !important;

  @media (max-width: 768px) {
    font-size: 16px !important;
    line-height: 24px !important;
  }
}
