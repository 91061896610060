.body {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background: #0572b9;
}

.card {
  max-width: 90%;
  border-radius: 0.25rem;
}

.logo {
  height: 60px;
  width: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
}

.button {
  &:disabled,
  &[data-disabled] {
    background-color: #cad9e4;
    color: grey;
  }
}

.textLink {
  cursor: pointer;
}
