.body {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background: #f8f9fa;
}

.card {
  max-width: 90%;
  border-radius: 8rem;
}

.logo {
  height: 60px;
  width: 160px;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  /* text-align: center; */
  margin: 0 auto;
}

.button {
  background-color: #0472b9;
  color: white;

  &:disabled,
  &[data-disabled] {
    background-color: #cad9e4;
    color: grey;
  }
}

.textLink {
  cursor: pointer;
}
