.body {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* height: 100vh; */
  min-height: 100vh;
  background: #f8f9fa;
  padding: 10px;
  overflow: hidden;
}

.card {
  max-width: 90%;
  border-radius: 8rem;
}

.logo {
  height: 60px;
  width: 160px;
  align-items: left;
  justify-content: left;
  margin: 0 auto;
}

.button {
  background-color: #0472b9;
  color: white;

  &:disabled,
  &[data-disabled] {
    background-color: #cad9e4;
    color: grey;
  }
}

.textLink {
  cursor: pointer;
}

.flexBox {
  display: flex;
  flex-direction: row;
  gap: 20px;

  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: center;
  }
}

.flexbetween {
  display: flex;
  justify-content: space-between;
}

.boxApp {
  border: 2px solid gray;
  border-radius: 20px;
  cursor: pointer;

  &:hover {
    border: 3px solid #0472b9;
    color: #0472b9;
  }
}
