.link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #0572b9(255, 255, 255);
  padding: 0.625rem 0.75rem;
  border-radius: 0.25rem;
  font-weight: 500;
  font-size: 14px !important;

  &:hover {
    background-color: rgb(248, 249, 250);
    color: #0572b9;
  }

  &[data-active] {
    color: #ffffff;
    font-weight: 700;
    background-color: #0572b9;
  }
}

.linkIcon {
  color: #0572b9 !important;
  margin-right: 16px;

  &[data-active] {
    color: #ffffff !important;
  }
}
